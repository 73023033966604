<template>
  <appDialog
    btnSize="mini"
    btnType="text"
    btnText="修改输入样式"
    title="修改输入样式"
    width="600"
    :option="option"
    :beforeSubmit="beforeSubmit"
    @submit="onsubmit"
    @open="onopen"
    v-bind="$attrs"
  >
    <BaseForm1 ref="form" class="mt20" v-model="form" :option="option">
      <!-- 亚马逊-最大长度 -->
      <template #valueMaxLength>
        <el-input-number v-model="form.valueMaxLength" :precision="0" :step="1" :min="1" label="请输入最大长度"></el-input-number>
      </template>
    </BaseForm1>
  </appDialog>
</template>

<script>
import baseForm1 from '@/components/base/baseForm1'
import {
  AMAZON_TEXT_TYPE_DIC,
  FIELD_TYPE_DIC,
  FIELD_TYPE_INPUT,
  FIELD_TYPE_TEXTAREA
} from '@/utils/constant/fieldConst'
import { charLenLimitConstructor, requireFun } from '@/utils'
import { pick } from 'lodash'

export default {
  components: {
    baseForm1
  },
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    option() {
      return {
        size: 'small',
        labelWidth: 100,
        span: 20,
        column: [
          {
            label: '输入样式',
            prop: 'fieldType',
            type: 'select',
            dicData: FIELD_TYPE_DIC,
            rules: [
              {
                required: true,
                message: requireFun('输入样式')
              }
            ],
            control(value) {
              const type = [FIELD_TYPE_INPUT, FIELD_TYPE_TEXTAREA].includes(+value)
              return {
                valueFormatType: {
                  hide: !type
                },
                valueMaxLength: {
                  hide: !type
                }
              }
            }
          },
          {
            label: '文本格式',
            prop: 'valueFormatType',
            dicData: AMAZON_TEXT_TYPE_DIC,
            type: 'select',
            rules: [
              {
                required: true,
                message: requireFun('文本格式')
              }
            ]
          },
          {
            label: '最大长度',
            prop: 'valueMaxLength',
            type: 'number',
            rules: [
              {
                required: true,
                message: requireFun('最大长度')
              },
              {
                validator: charLenLimitConstructor(1,5)
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    onsubmit(form1, done) {
      const { form, column } = this
      Object.assign(column, pick(form, ['fieldType', 'valueFormatType', 'valueMaxLength']))
      this.$set(this.data, 'updateProp', column.prop) // 当前更新字段
      this.$set(this.data, 'updateId', +new Date()) // 触发更新（updateProp可能不变）
      done()
    },
    beforeSubmit() {
      return this.$refs.form.validate()
    },

    onopen() {
      this.form = pick(this.column, ['fieldType', 'valueFormatType', 'valueMaxLength'])
    }
  }
}
</script>

<style lang="scss">

</style>
